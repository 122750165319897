import {
  Text,
  SubTitle,
  ListItem,
  ApplicationColumnContainer,
  UnorderedList,
  ApplicationColumnLeft,
  ApplicationColumnRight,
  BulletlessList,
  Callout,
  CalloutColumn,
  /*FormIframe,*/
  Link,
  ApplicationSection,
} from './styles';
import { EXTERNAL_LINKS } from '../../utils/links';

export const TheApplication = () => {
  return (
    <ApplicationSection id="form">
      <SubTitle>📝 The Application</SubTitle>
      <Text>
        Applicants must be UW Seattle students graduating Fall 2025 or later. Commitment for DubHacks Team 2025 will be
        from January 2025 to November 2025.
      </Text>
      <ApplicationColumnContainer>
        <ApplicationColumnLeft>
          <Text>
            <b>Basic Information</b>
            <br />
            <BulletlessList>
              <ListItem>
                <i>Coming soon!</i>
              </ListItem>
              {/*<ListItem>Name</ListItem>
              <ListItem>Email</ListItem>
              <ListItem>Phone</ListItem>
              <ListItem>Graduation Date</ListItem>
              <ListItem>Major(s)</ListItem>
              <ListItem>Minor(s)</ListItem>
              <ListItem>Resume</ListItem>
              <ListItem>Links</ListItem>*/}
            </BulletlessList>
          </Text>
        </ApplicationColumnLeft>
        <ApplicationColumnRight>
          <Text>
            <b>DubHacks Questions</b>
            <br />
            <UnorderedList>
              <ListItem>
                <i>Coming soon!</i>
              </ListItem>
              {/*<ListItem>Why are you interested in joining the DubHacks team? (150 words max)</ListItem>
              <ListItem>
                What special skills, experiences, and/or perspectives can you bring to our team? Please be specific.
                (250 words max)
              </ListItem>
              <ListItem>
                Have you worked before on a long term project for an organization (RSO, club, nonprofit, etc.) you were
                passionate about? What did you do? (100 words max)
              </ListItem>
              <ListItem>
                What personality traits make you the best choice for our team? What can you bring to the DubHacks
                community? (60 words max)
              </ListItem>
              <ListItem>What hidden talent do you show off most often? (20 words max)</ListItem>*/}
            </UnorderedList>
          </Text>
        </ApplicationColumnRight>
      </ApplicationColumnContainer>
      <Text>
        <b>Subteam Questions</b>
        <br />
        <UnorderedList>
          <ListItem>
            <i>Coming soon!</i>
          </ListItem>
          {/*<ListItem>
            <i>Design and Tech subteams require a portfolio.</i>
          </ListItem>
          <ListItem>What role(s) are you interested in? </ListItem>
          <ListItem>Why is this the role you are most interested in? (50 words max)</ListItem>
          <ListItem>
            What is your list of tangible skills that make you the best choice for this role? (15 words max)
          </ListItem>*/}
        </UnorderedList>
      </Text>
      <Text>
        To learn more about the team, don't hesitate to reach out to&nbsp;
        <Link href={EXTERNAL_LINKS.DH_EMAIL} target="_blank">
          hello@dubhacks.co
        </Link>
      </Text>
      <Callout>
        <CalloutColumn>✅</CalloutColumn>
        <CalloutColumn>
          Applications open Nov. 21st 2024 and will be available on this page. See you soon!
          {/*&nbsp;
          <Link href={EXTERNAL_LINKS.DH_TEAM_25_FORM} target="_blank">
            Click here to submit your app externally.
          </Link>*/}
          {/*<b>Applications are due January 9th, 2025 @ 11:59pm PST.</b>*/}
        </CalloutColumn>
      </Callout>
      {/*<FormIframe src={EXTERNAL_LINKS.DH_TEAM_25_FORM} frameBorder="0"></FormIframe>*/}
    </ApplicationSection>
  );
};
