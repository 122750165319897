import { Text, SubTitle, ListItem, UnorderedList, Card } from './styles';

export const TechTeamCard = () => {
  return (
    <Card>
      <SubTitle>💻 Tech</SubTitle>
      <Text>
        As a member of our Tech Team, you will deliver development projects ranging from websites, virtual hackathon
        features, and all other internal tools. A successful Tech Team member will have full stack experience working on
        a cross-functional team and is excited about building creative hackathon applications to improve the hacker and
        organizer experience. <b>Tech team applicants must submit a portfolio.</b>
      </Text>
      <Text>
        Responsibilities include:
        <UnorderedList>
          <ListItem>
            Building internal tools and strategies to support all DH operations - likely working with Docker, Twilio,
            Google product APIs, and Discord automation.
          </ListItem>
          <ListItem>
            Collaborating with Design team to build and deploy DH websites with React, TypeScript, and Node.js.
          </ListItem>
          <ListItem>Writing code to an industry standard following best development practices.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        Useful skills/experiences:
        <UnorderedList>
          <ListItem>Experience writing and using APIs and deploying personal projects.</ListItem>
          <ListItem>Full stack JS/TS experience, especially with React.</ListItem>
          <ListItem>Experience writing code to an industry standard in a team setting.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        After your time at DubHacks you will have:
        <UnorderedList>
          <ListItem>Deployed real applications for 1000+ users.</ListItem>
          <ListItem>Built live websites to a professional standard using the latest tools.</ListItem>
          <ListItem>
            Designed and written large systems for judging, hacker authentication, and hacker profiles.
          </ListItem>
        </UnorderedList>
      </Text>
    </Card>
  );
};
