import { Text, SubTitle, ListItem, UnorderedList, Card } from './styles';

export const DesignTeamCard = () => {
  return (
    <Card>
      <SubTitle>🎨 Design</SubTitle>
      <Text>
        As a Designer for DubHacks, you will create the DH brand and design visual assets for our web and mobile
        platforms, social media accounts, promotional items, and more for our hackathon. A successful designer will have
        experience working on a cross-functional team and is excited about building a brand identity that embodies our
        mission. <b>Design team applicants must submit a portfolio.</b>
      </Text>
      <Text>
        Responsibilities include:
        <UnorderedList>
          <ListItem>
            Defining a visual brand for all DH events and programs (hackathons, startup incubator, social media post
            etc.)
          </ListItem>
          <ListItem>Collaborating with Tech team to implement website designs.</ListItem>
          <ListItem>Coordinating with relevant teams to design all visual assets.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        Useful skills/experiences:
        <UnorderedList>
          <ListItem>Knowledge of Figma, Procreate, and Adobe Illustrator.</ListItem>
          <ListItem>Experience with visual or brand designs.</ListItem>
          <ListItem>A technical understanding of UI/UX.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        After your time at DubHacks you will have:
        <UnorderedList>
          <ListItem>Designed branding materials for merchandise, swag, and DH identity for 1,000+ users.</ListItem>
          <ListItem>
            Created the DubHacks official hackathon website and the Hacker Profile with your original visual assets.
          </ListItem>
          <ListItem>
            Develop and master Visual Design, Illustration & Animation, Web Design, Branding, and Technical
            Communications.
          </ListItem>
        </UnorderedList>
      </Text>
    </Card>
  );
};
