import { Text, SubTitle, ListItem, UnorderedList, Card } from './styles';

export const SponsorshipTeamCard = () => {
  return (
    <Card>
      <SubTitle>💸 Sponsorship</SubTitle>
      <Text>
        As a member of the Sponsorship Team for DubHacks, you will build relationships with corporate contacts and
        manage all aspects of the sponsor experience. Sponsorship is crucial to delivering meaningful opportunities for
        connection between our community of hackers and industry professionals. A successful Sponsorship team member
        will be an excellent professional communicator, negotiator, and salesperson.
      </Text>
      <Text>
        Responsibilities include:
        <UnorderedList>
          <ListItem>Crafting Sponsorship strategy and content.</ListItem>
          <ListItem>Creating custom prospectus and sponsor packages to attract specific partners.</ListItem>
          <ListItem>Assisting sponsors to enable and streamline their experience during the hackathon event.</ListItem>
          <ListItem>Corresponding professionally and frequently with our sponsor partners through email.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        Useful skills/experiences:
        <UnorderedList>
          <ListItem>Any previous sales experience.</ListItem>
          <ListItem>Past work partnering with business/corporate professionals.</ListItem>
          <ListItem>Dedication and resourcefulness.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        After your time at DubHacks you will have:
        <UnorderedList>
          <ListItem>Successfully managed and coordinated funds for the hackathon.</ListItem>
          <ListItem>Demonstrated and gained experience in both stakeholder and program management.</ListItem>
          <ListItem>Established and managed relationships with corporate and industry professionals.</ListItem>
        </UnorderedList>
      </Text>
    </Card>
  );
};
