import { Text, SubTitle, ListItem, UnorderedList, Card } from './styles';

export const MarketingTeamCard = () => {
  return (
    <Card>
      <SubTitle>📲 Marketing</SubTitle>
      <Text>
        As a member of the Marketing Team for DubHacks, you will pioneer the organization's brand strategy and lead all
        related content efforts for our hackathon and Next teams. Marketing is crucial to expanding DubHacks to a
        larger, more diverse audience and will directly shape our organization's brand presence.
      </Text>
      <Text>
        Responsibilities include:
        <UnorderedList>
          <ListItem>
            Creating marketing content on all relevant social platforms (Instagram, LinkedIn, TikTok, etc.).
          </ListItem>
          <ListItem>
            Managing all external communications and establishing partnerships/avenues for marketing purposes.
          </ListItem>
          <ListItem>
            Crafting marketing brand strategy (planning events, organizing outreach, etc) that embodies the DH mission.
          </ListItem>
        </UnorderedList>
      </Text>
      <Text>
        Useful skills/experiences:
        <UnorderedList>
          <ListItem>
            Experience with outreach communication and marketing (RSO fair, Dawg Daze, emails, flyering).
          </ListItem>
          <ListItem>Knowledge of social media best practices.</ListItem>
          <ListItem>Video editing and photo editing experience.</ListItem>
          <ListItem>A go-getter mindset to add innovative ideas to the brand campaign.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        After your time at DubHacks you will have:
        <UnorderedList>
          <ListItem>
            Launched a two-month multi-platform marketing campaign for the largest hackathon in the PNW.
          </ListItem>
          <ListItem>Developed brand strategy and brand image for the flagship DubHacks hackathon.</ListItem>
          <ListItem>
            Produced a wide variety of posts, slides, videos, presentations, etc. collaborating with designers and
            artists.
          </ListItem>
        </UnorderedList>
      </Text>
    </Card>
  );
};
