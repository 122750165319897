import React from 'react';
/*import { GoogleForm } from '../components/GoogleForm';*/
import { ApplyInfo } from '../components/ApplyInfo/ApplyInfo';

const PageJoinBase: React.FC = props => {
  return (
    <div>
      <ApplyInfo />
      {/*<GoogleForm />*/}
    </div>
  );
};

export default PageJoinBase;
