import { Text, SubTitle, ListItem, UnorderedList, Card } from './styles';

export const LogisticsTeamCard = () => {
  return (
    <Card>
      <SubTitle>🛠️ Logistics</SubTitle>
      <Text>
        As a member of the Logistics Team for DubHacks, you will lead all event-related tasks and drive the details
        behind fantastic hacker experience. A successful Logistics Team member will be well organized, an excellent
        multitasker, and a cross-team communicator.
      </Text>
      <Text>
        Responsibilities include:
        <UnorderedList>
          <ListItem>
            Managing speakers, food, mentors, swag, prizes, venue, and all other day-of hackathon activity logistics.
          </ListItem>
          <ListItem>
            Coordinating and presenting all necessary resources for hacker and sponsor day-of hackathon engagement.
          </ListItem>
          <ListItem>Brainstorming and spearheading hackathon opportunities aligned with the DH mission.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        Useful skills/experiences:
        <UnorderedList>
          <ListItem>Experience planning large scale events (hackathons, trips, dances, programs, etc.).</ListItem>
          <ListItem>Project/program management through RSOs, internships, or external organizations.</ListItem>
          <ListItem>Knowledge of how hackathon events are organized.</ListItem>
          <ListItem>Previous budgeting or accounting experience.</ListItem>
        </UnorderedList>
      </Text>
      <Text>
        After your time at DubHacks you will have:
        <UnorderedList>
          <ListItem>Coordinated cross-functional initiatives across multiple teams.</ListItem>
          <ListItem>Hosted a 1000 person event while directing a team of 30 people.</ListItem>
          <ListItem>
            Managed multiple concurrent 6-month projects: defining scope, costs, timelines, and milestones.
          </ListItem>
        </UnorderedList>
      </Text>
    </Card>
  );
};
