import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../utils/theme';

import DownArrow from '../assets/downarrow.svg';

const HeroContainer = styled.div`
  pointer-events: none;
  display: flex;
  height: 85vh;
  padding: 100px 0 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

const HeroAlignmentContainer = styled.div`
  pointer-events: none;
  position: relative;
  z-index: 2;
  max-width: 65%;
  margin: 0 auto 170px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: ${THEME_COLORS.brandPink};
  background-image: linear-gradient(
    65deg,
    ${THEME_COLORS.brandPink} 40%,
    ${THEME_COLORS.orangeSaturated} 50%,
    ${THEME_COLORS.brandPink} 55%,
    ${THEME_COLORS.blueSaturated} 65%,
    ${THEME_COLORS.brandPink} 75%
  );
  background-clip: border-box;
  background-size: 500% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 20s ease-in-out infinite;
  display: inline-block;
  @media (max-width: 767px) {
    margin-top: 170px;
  }
  @keyframes textclip {
    0% {
      background-position: 0% center;
    }
    100% {
      background-position: 100% center;
    }
  }
`;

const DH = styled.h1`
  display: block;
  width: 100%;
  pointer-events: none;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Gemunu Libre', sans-serif;
  font-size: 13.8vw;
  line-height: 1;
  font-weight: 800;
  text-align: center;
`;

const BottomText = styled.div`
  pointer-events: none;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
  font-family: 'Catv 6x12 9', sans-serif;
  font-size: 2vw;
  line-height: 1;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const BottomArrow = styled.a`
  background-image: url(${DownArrow});
  position: relative;
  z-index: 99;
  display: block;
  width: 7%;
  min-height: 30px;
  margin-right: auto;
  margin-left: auto;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  text-align: left;
  pointer-events: all;
`;
export const Hero = () => {
  const handleScroll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href')?.slice(1); // Remove '#' from href
    const targetElement = targetId ? document.getElementById(targetId) : null;

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <HeroContainer>
        <HeroAlignmentContainer>
          <DH>DUBHACKS</DH>
          <BottomText>building tomorrow together</BottomText>
        </HeroAlignmentContainer>
        <BottomArrow href="#orgs" onClick={handleScroll} />
      </HeroContainer>
    </>
  );
};
