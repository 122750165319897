import { EXTERNAL_LINKS } from '../../utils/links';
import { DesignTeamCard } from './DesignTeamCard';
import { LogisticsTeamCard } from './LogisticsTeamCard';
import { MarketingTeamCard } from './MarketingTeamCard';
import { SponsorshipTeamCard } from './SponsorshipTeamCard';
import { TheApplication } from './TheApplication';
import { Slideshow } from './Slideshow';
import {
  ApplyInfoSection,
  Title,
  Callout,
  CalloutColumn,
  Text,
  Link,
  SubTitle,
  OrderedList,
  ListItem,
  TeamsGrid,
  PermaCallout,
} from './styles';
import { TechTeamCard } from './TechTeamCard';

export const ApplyInfo = () => {
  /*const handleScroll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href')?.slice(1); // Remove '#' from href
    const targetElement = targetId ? document.getElementById(targetId) : null;

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 50,
        behavior: 'smooth',
      });
    }
  };*/

  return (
    <ApplyInfoSection>
      <Title>DubHacks Team 2025 Hiring</Title>
      {/*<PermaCallout href="#form" onClick={handleScroll}>*/}
      <PermaCallout href={EXTERNAL_LINKS.DH_INSTAGRAM} target="_blank">
        {/*<b>🤩 Take me to the application!! 🤩</b>*/}
        <b>👩🏾‍💻 Attend our in-person Info Session on Nov. 21st to chat with the team!! 🧑🏻‍🎨</b>
      </PermaCallout>
      <Callout>
        <CalloutColumn>✅</CalloutColumn>
        <CalloutColumn>
          Applications open Nov. 21st 2024 and will be available on this page. See you soon!
          {/*<b>
            Applications are due January 9th, 2025 @ 11:59pm PST. Submit your app&nbsp;
            <Link href="#form" onClick={handleScroll}>
              below
            </Link>
            !
          </b>*/}
        </CalloutColumn>
      </Callout>
      <Text>
        <i>
          Must be a student at UW Seattle graduating Fall 2025 or later to apply. If you have any questions, please
          reach out to&nbsp;
          <Link href={EXTERNAL_LINKS.DH_EMAIL} target="_blank">
            hello@dubhacks.co
          </Link>
        </i>
      </Text>
      <SubTitle>💖 Our Community</SubTitle>
      <Slideshow />
      <Text>
        We cultivate a community of the kindest and friendliest people we can find. It takes a diverse set of skills to
        plan the largest hackathon in the Pacific Northwest: we need programmers, designers, managers, salespeople,
        artists, planners, and more. As a result,{' '}
        <b>the DubHacks team comes from a huge variety of backgrounds and majors</b>.
      </Text>
      <Text>
        Beyond the hackathon, professional development is our top priority. Our team has gone on to work at{' '}
        <b>
          Meta, Google, Apple, SpaceX, BCG, Accenture, Deloitte, Tesla, Two Sigma, Amazon, Microsoft, Palantir, NASA,
          Tiffany and Co., X (Twitter), Databricks, Visa, Stripe, Roblox, Uber, Capital One, Oracle, and beyond
        </b>
        .
      </Text>
      <SubTitle>🌟 Our Mission</SubTitle>
      <Text>
        <b>
          DubHacks is a tech and entrepreneurship non-profit in Seattle run by students at the University of Washington
        </b>
        . We bring together students of all backgrounds to tackle the issues they are most passionate about. We:
        <OrderedList>
          <ListItem>Challenge students to create technology responsibly.</ListItem>
          <ListItem>
            Engage and center people who are underrepresented in the industry to advance equity in tech.
          </ListItem>
          <ListItem>Connect students to resources that support their development as technologists.</ListItem>
          <ListItem>Foster a welcoming and vibrant community for students to experiment and grow together.</ListItem>
        </OrderedList>
      </Text>
      <SubTitle>👪 Our Team</SubTitle>
      <Callout>
        <CalloutColumn>📅</CalloutColumn>
        <CalloutColumn>
          Commitment for DubHacks Team 2025 will be from Jan. 2025 to Nov. 2025. Applicants must be graduating Fall 2025
          or later.
        </CalloutColumn>
      </Callout>
      <Text>
        DubHacks is unique because we need talent from a huge variety of majors and experiences. DubHacks has 5 subteams
        that each perform different functions and require different skills. When you apply,{' '}
        <b>you will need to select which subteams you're interested in joining</b>.
      </Text>
      <TeamsGrid>
        <TechTeamCard />
        <DesignTeamCard />
        <LogisticsTeamCard />
        <SponsorshipTeamCard />
        <MarketingTeamCard />
      </TeamsGrid>
      <TheApplication />
    </ApplyInfoSection>
  );
};
